<template>
  <div class="text-sm space-y-6">
    <vf-notification v-if="error" type="error" :dismissible="false">
      {{ $t.questionsError }}
    </vf-notification>
    <template v-else>
      <h2 class="text-center title-2" data-test-id="product-questions">
        {{ $t.qAndA }}
      </h2>
      <div class="text-center" data-test-id="product-question-add">
        <vf-button class="<md:w-full" @click="ModalAskQuestion.open({ productId })">
          {{ $t.askAQuestion }}
        </vf-button>
      </div>
    </template>
    <section v-if="pending || questions?.length || questionsQuery.q" data-test-id="product-reviews-search">
      <!-- Search, Sorting -->
      <section class="space-y-4">
        <div class="bg-grey-90 p-4 text-center lg:flex lg:items-center lg:between <md:-mx-4">
          <p class="text-base <lg:mb-4">
            {{ $t.searchForAnswersToYourQuestions }}
          </p>
          <form role="search" class="flex" @submit.prevent="search">
            <vf-input class="h-10 rounded-r-0 <lg:grow">
              <template #input>
                <base-input
                  v-model="searchTerm"
                  type="text"
                  :placeholder="$t.searchQuestions"
                  :aria-label="$t.searchQuestions"
                  class="h-full"
                  maxlength="50"
                />
              </template>
              <template #end>
                <base-button v-if="searchTerm" :aria-label="$t.searchClearLabel" @click="clearSearch">
                  <vf-icon name="clear" size="md" />
                </base-button>
              </template>
            </vf-input>
            <base-button
              type="submit"
              class="rounded-r-sm bg-brand-2 p-1 c-white"
              :aria-label="$t.searchButtonLabel"
            >
              <vf-icon name="search" size="lg" />
            </base-button>
          </form>
        </div>
        <div class="flex justify-end">
          <select-sort v-model="sort" :label="$t.sort" :options="sortOptions" @update:model-value="sortQuestions" />
        </div>
      </section>
      <!-- Questions List -->
      <section id="customerQuestions" :class="currentQuestionsCount ? 'space-y-8' : 'pt-4'" data-scroll-el="customerQuestions">
        <section class="pt-6 space-y-6" data-test-id="customer-questions">
          <product-reviews-question-detail
            v-for="question in questions"
            :key="question.question_id"
            :question="question"
            :search-term="questionsQuery.q"
            :product-id="productId"
          />
        </section>

        <div class="f-col center md:flex-row md:between" :class="{ 'gap-1': pagesCount > 1 }">
          <div class="f-col center gap-2 md:flex-row md:gap-4">
            <p :class="currentQuestionsCount ? 'c-grey-20' : 'text-base'" data-test-id="display-reviews">
              {{ currentQuestionsDisplayedText }}
            </p>
            <base-link
              v-if="currentQuestionsDisplayedCount > backToTopThreshold"
              class="underlined"
              data-test-id="questions-back-to-top"
              to="#customerQuestions"
            >
              {{ $t.backToTop }}
            </base-link>
          </div>
          <div class="flex">
            <base-button v-if="currentPageNumber > 1" class="underlined" @click="showPrevious">
              {{ $t.previous }}
            </base-button>
            <span v-if="currentPageNumber > 1 && currentPageNumber < pagesCount" class="mx-2">|</span>
            <base-button
              v-if="pagesCount && currentPageNumber < pagesCount"
              class="underlined"
              data-test-id="questions-next"
              @click="showNext"
            >
              {{ $t.next }}
            </base-button>
          </div>
        </div>
      </section>
    </section>

    <modal-ask-question />
    <modal-ask-question-success />
  </div>
</template>

<script lang="ts" setup>
import type { ProductReviewsQuestionsResponse } from '#root/api/clients/product/data-contracts'
import type { ProductQuestionsParams } from '#types/components/product/reviews'

const props = defineProps<{
  questionsData?: ProductReviewsQuestionsResponse | null
  productId: string
  pending: boolean
  error: any
}>()

const questionsQuery = defineModel<ProductQuestionsParams>({ required: true })

const ModalAskQuestion = createModal('ask-question')
const { ModalAskQuestionSuccess } = useDialogsStore()

const { $t } = useNuxtApp()
const { questions: questionsConfig } = useAppConfig().pages.pdp
const pageSize = questionsConfig.pageSize
const searchTerm = ref()
const backToTopThreshold = 3

const questions = computed(() => props.questionsData?.results || [])
const currentQuestionsCount = computed(() => props.questionsData?.paging.total_results || 0)
const pagesCount = computed(() => props.questionsData?.paging.pages_total || 1)
const currentPageNumber = computed(() => props.questionsData?.paging.current_page_number || 1)
const start = computed(() => ((currentPageNumber.value - 1) * pageSize) + 1)
const end = computed(() => Math.min(currentQuestionsCount.value, currentPageNumber.value * pageSize))

const currentQuestionsDisplayedText = computed(() => currentQuestionsCount.value
  ? replaceAll($t.displayingQuestions, { start: start.value, end: end.value })
  : $t.noResults)

const currentQuestionsDisplayedCount = computed(() => end.value - start.value + 1)

const sortOptions = [
  { value: 'Newest', label: $t.reviewSortOptionNewest },
  { value: 'Oldest', label: $t.reviewSortOptionOldest }
]

const sort = ref('Newest')

const scrollToCustomerQuestions = () => scrollToElement('customerQuestions')

const sortQuestions = (sortBy: string) => {
  questionsQuery.value.sort = sortBy
}

const showPrevious = () => {
  questionsQuery.value.pagingFrom = ((currentPageNumber.value - 1) * pageSize) - pageSize
  scrollToCustomerQuestions()
}

const showNext = () => {
  questionsQuery.value.pagingFrom = ((currentPageNumber.value + 1) * pageSize) - pageSize
  scrollToCustomerQuestions()
}

const search = () => {
  questionsQuery.value.q = searchTerm.value ? searchTerm.value : undefined
  questionsQuery.value.pagingFrom = 0
}

const clearSearch = () => {
  searchTerm.value = undefined
  search()
}

onBeforeUnmount(() => {
  questionsQuery.value.pagingFrom = 0
  questionsQuery.value.sort = 'Newest'
  questionsQuery.value.q = undefined
})
</script>
