<template>
  <vf-accordion
    v-for="({ data, id, label, layout = 'grid', expanded }, i) in productDetails"
    :id
    :key="i"
    :open="$feature.allowOpenedDetailsAccordionsOnPDP ? expanded : i === 0"
    :title="label"
    variant="aligned"
    :class="{ 'b-b': i === productDetails.length - 1 }"
  >
    <div class="pb-6 pt-2 text-sm c-grey-20">
      <component
        :is="componentTypes[DETAIL_LAYOUTS.includes(layout) ? layout : 'detailCard']"
        :title="label"
        :data
      />
    </div>
  </vf-accordion>
</template>

<script setup lang="ts">
import {
  LazyProductDetailsBulletin,
  LazyProductDetailsDetailCard,
  LazyProductDetailsGrid,
  LazyProductDetailsKeyValue,
  LazyProductDetailsModalGrid,
  LazyProductDetailsScale
} from '#components'
import type { ProductDetails } from '#root/api/clients/product/data-contracts'
import { DETAIL_LAYOUTS } from '#types/product'

defineProps<{
  productDetails: ProductDetails[]
}>()

const componentTypes = {
  bulletin: LazyProductDetailsBulletin,
  detailCard: LazyProductDetailsDetailCard,
  grid: LazyProductDetailsGrid,
  keyValue: LazyProductDetailsKeyValue,
  modalGrid: LazyProductDetailsModalGrid,
  scale: LazyProductDetailsScale
}
</script>
