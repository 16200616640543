<template>
  <!-- We should extract "validate" as a standalone function in order to not use the BaseForm wrapper -->
  <component :is="asForm ? BaseForm : 'div'" ref="attributeSelectionFormRef" :form="attributeSelection">
    <div :class="brandClasses.variantSelectionWrapper">
      <section
        v-for="({ groupBy, groupedOptions, label, type }, i) in variantAttributes"
        :key="type"
                :data-test-id="`${type}-groups`"
        :data-scroll-el="`${type}Pickers`"
      >
        <div
          v-if="!(isCustomsProduct && type === 'color')"
          :class="{ 'flex items-center space-x-2': showBadge }"
        >
          <div v-if="showBadge" class="h-4 w-4 flex justify-center rounded-full bg-grey-10">
            <span class="text-xs c-white fw-bold">{{ i + 1 }}</span>
          </div>
          <div :id="label" class="text-sm ">
            <h2 class="inline fw-bold">
              {{ label }}:
            </h2>
            <p class="inline">
              {{ attributeSelectionLabels[type] }}
            </p>
          </div>
        </div>
        <vf-form-error v-if="type !== 'color' && error && !attributeSelection[type]" class="mt-1">
          {{ product.productType === 'Digital' ? $t.selectCardAmountError : $t.selectSizeError }}
        </vf-form-error>
        <template v-if="!(isCustomsProduct && type === 'color')">
          <div
            v-for="({ groupValue, options }, j) in groupedOptions"
            :key="j"
            class="mt-2"
            :class="{ 'mb-4': j !== groupedOptions.length - 1 }"
            :data-test-id="`${type}-groups-item`"
          >
            <product-pricing
              v-if="groupValue && groupedOptions.length > 1 && groupBy === 'price'"
              :price="groupValue"
              :currency="product.currency"
              class="mb-2"
            />
            <component
              :is="options.length > swatches.collapseThreshold ? BaseCollapse : 'div'"
              v-if="type === 'color'"
              v-bind="options.length > swatches.collapseThreshold && {
                classToggle: 'self-end ',
                classContent: isRectangle ? 'max-h-48 ' : 'max-h-54 lg:max-h-75',
                disableVisibilityToggle: true,
                inverted: true,
              }"
            >
              <fieldset :aria-labelledby="label" class="flex wrap" :class="{ 'gap-1 ': isRectangle }">
                <vf-color-picker
                  v-for="{ available, id, label: optionLabel, swatch, url, value } in options"
                  :key="optionLabel"
                  :color="swatch?.color"
                  :thumbnail="swatch?.image"
                  :active="!getIsPending(value, id, pending) && getIsActive(value, id)"
                  :loading="getIsPending(value, id, pending)"
                  :size="colorPickerSize"
                  :variant="colorPickerShape"
                  :unavailable="!available && !getIsPending(value, id, pending)"
                  :name="optionLabel"
                  @click="selectNewColor(enableColorAsAttribute ? value : id, url, optionLabel)"
                />
              </fieldset>
              <!-- toggle for base-collapse -->
              <template #toggle="{ expanded }">
                <div >
                  <p class="py-2 text-right text-sm lh-3 underlined lg:py-4">
                    {{ expanded ? $t.viewLess : $t.viewMore }}
                  </p>
                  <vf-icon
                    v-if="swatches.showCollapseIcon"
                    name="chevron"
                    size="md"
                    class="ml-1"
                    :dir="expanded ? 'up' : 'down'"
                  />
                </div>
              </template>
            </component>
            <fieldset v-else :aria-labelledby="label">
              <base-form-field v-slot="{ attrs }" :name="type" :rule="validateRequired()" :selector="`${type}Pickers`">
                <div class="flex gap-2 wrap" :class="{ '': type === 'size' }">
                  <vf-size-picker
                    v-for="({ available, label: optionLabel, id, value }, n) in options"
                    :key="optionLabel"
                    :type
                    :model-value="attributeSelection[type]"
                    :value
                    :name="label"
                    :required="n === 0"
                    :unavailable="!getIsPending(value, id, pending)
                      && (!available || !getAttrOptionAvailable(type, value))"
                    v-bind="attrs"
                    @input="$emit('selectSize', type, value, optionLabel)"
                  >
                    {{ optionLabel }}
                  </vf-size-picker>
                </div>
              </base-form-field>
            </fieldset>
          </div>
        </template>
        <vf-link
          v-if="$feature.showCustomizeOnPDP && product.customsExternalurl && type === 'color'"
          :to="product.customsExternalurl"
          class="items-center gap-1 text-sm fw-bold lh-3 i-flex hover:c-brand-3"
          :class="{ 'mt-4': !isCustomsProduct }"
        >
          <vf-icon name="customs" size="md" /> {{ $t.customize }}
        </vf-link>
        <div v-if="isComingSoon" class="mt-4 space-y-4 ">
          <p class="title-4 c-grey-20 ">
            {{ comingSoonText }}
          </p>
          <p v-if="selectedVariant?.notifyMe">
            {{ replaceAll($t.comingSoonDescription, { productName: product.name }) }}
          </p>
        </div>
      </section>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { formatDate } from '@vueuse/core'
import { BaseCollapse, BaseForm } from '#components'
import type { BaseForm as BaseFormType } from '#components'
import type { AttributeSelection, ColorSwatchShape, ProductAttributeType, ProductExtended, SelectedVariant } from '#types/product'
import type { Responsive } from '#types/common'
import type { SizesSubUnion } from '#types/sizes'

const props = defineProps<{
  /*
  * variants, showNotifyMe, productInventory props are added only for consistency with other brands
  * They aren't used in the component
  * but allows to avoid situations with incorrect props resolution when rendering the DOM
  */
  attributeSelectionLabels: Record<string, string>
  colorSize?: SizesSubUnion<'sm' | 'md' | 'lg'> | Responsive<SizesSubUnion<'sm' | 'md' | 'lg'>>
  colorSwatch?: ColorSwatchShape
  error?: boolean
  isComingSoon?: boolean
  pending?: boolean
  product: ProductExtended
  selectedVariant?: SelectedVariant | null
  showBadge?: boolean
  asForm?: boolean
  showNotifyMe?: any
  showSizeAndFit?: boolean
  variants?: any
}>()

const emit = defineEmits<{
  selectColor: [id: string, url: string, optionLabel: string]
  selectSize: [type: Exclude<ProductAttributeType, 'color'>, value: string, label: string]
}>()

const { brandClasses, enableColorAsAttribute, swatches } = useAppConfig().pages.pdp || {}
const { $feature, $t } = useNuxtApp()
const attributeSelectionFormRef = defineModel<InstanceType<typeof BaseFormType> | null>('formRef', { required: false })

const colorPickerShape = props.colorSwatch ?? swatches.variant
const colorPickerSize = props.colorSize ?? swatches.size
const isRectangle = colorPickerShape === 'rectangle'
const attributeSelection = defineModel<AttributeSelection>({ required: true })

const selectedProductId = ref()

const variantAttributes = computed(() =>
  props.isComingSoon ? props.product.attributes.filter((attribute) => attribute.type === 'color') : props.product.attributes)

const selectNewColor = (id, url, optionLabel) => {
  selectedProductId.value = id
  emit('selectColor', id, url, optionLabel)
}

const getIsActive = (value: string, id: string) =>
  enableColorAsAttribute ? attributeSelection.value.color === value : props.product.id === id

const getIsPending = (value: string, id: string, pending?: boolean) => {
  if (!pending) return

  return enableColorAsAttribute
    ? (attributeSelection.value.color === value && id !== props.product.id)
    : selectedProductId.value === id
}

const isCustomsProduct = computed(() =>
  props.product.attributes.find(({ type }) => type === 'color')?.options?.some(({ label }) => label === 'Customs')
)

const comingSoonText = computed(() => {
  return props.product.targetPublishDate
    ? `${$t.comingSoon} - ${formatDate(new Date(props.product.targetPublishDate.replace(/-/g, '\/')), $feature.configProductComingSoonDateFormat)}`
    : $t.comingSoon
})

// This logic possibly can be moved to a useProduct
function getAttrOptionAvailable(type: ProductAttributeType, value: string) {
  // Attribute types except a current type and 'color'
  const otherAttributeTypes = props.product.attributes
    .filter((attribute) => !['color', type].includes(attribute.type))
    .map((attribute) => attribute.type)

  if (otherAttributeTypes.every((attributeType) => attributeSelection.value[attributeType])) {
    // If all other attribute types are selected, find the current option's variant
    const currentVariant = props.product.variants.find((variant) =>
      otherAttributeTypes.every((attributeType) =>
        variant.attributes[attributeType] === attributeSelection.value[attributeType])
        && variant.attributes[type] === value)

    return currentVariant?.productInventoryState === 'InStock'
  }

  // When the attribute option has other unselected attribute types, it's available
  return true
}

defineExpose({
  validate: () => attributeSelectionFormRef.value?.validate()
})
</script>
